import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestTextblockCategories } from 'actions/textblockCategories'
import SelectWrapper from './SelectWrapper'

const SelectTextblockCategory = (props) => {
  const { textblockCategories, dispatch } = props
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init && (!textblockCategories || textblockCategories.length === 0)) {
      setInit(true)
      dispatch(requestTextblockCategories())
    }
  }, [textblockCategories, dispatch, init, setInit])

  return (
    <div className="select select--grade">
      <label className="py-2 block text-sm font-medium text-gray-700">Beurteilungskategorie</label>
      <SelectWrapper>
        <select
          onChange={(e) => props.onChange(parseInt(e.target.value))}
          value={props.value || ""}
          className="w-full outline-none border appearance-none shadow-sm block border-gray-300 rounded-md"
        >
          <option value="" disabled>Auswählen...</option>
          {props.textblockCategories.sort((t1, t2) => {
            return t1.name > t2.name ? 1 : -1
          }).map(category => {
            return <option
              key={category.id}
              value={category.id}
            >
              {category.name} (ID {category.id})
            </option>
          })}
        </select>
      </SelectWrapper>
    </div>
  )
}

export default connect((state) => {
  const textblockCategories = state.entities && state.entities.textblock_categories
    ? Object.keys(state.entities.textblock_categories).map((key) => {
      return state.entities.textblock_categories[key]
    })
    : []
  return {
    textblockCategories,
  }
})(SelectTextblockCategory)